<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M24 8C15.1795 8 8 15.1795 8 24C8 32.8205 15.1795 40 24 40C32.8205 40 40 32.8205 40 24C40 15.1795 32.8205 8 24 8ZM24 37.5385C16.5333 37.5385 10.4615 31.4667 10.4615 24C10.4615 16.5333 16.5333 10.4615 24 10.4615C31.4667 10.4615 37.5385 16.5333 37.5385 24C37.5385 31.4667 31.4667 37.5385 24 37.5385Z"
        fill="inherit"/>
    <path
        d="M24.0001 26.4617C24.677 26.4617 25.2308 25.9078 25.2308 25.2309V16.6155C25.2308 15.9386 24.677 15.3848 24.0001 15.3848C23.3231 15.3848 22.7693 15.9386 22.7693 16.6155V25.2309C22.7693 25.9078 23.3231 26.4617 24.0001 26.4617Z"
        fill="inherit"/>
    <path
        d="M24 32.6152C25.0196 32.6152 25.8461 31.7886 25.8461 30.769C25.8461 29.7494 25.0196 28.9229 24 28.9229C22.9804 28.9229 22.1538 29.7494 22.1538 30.769C22.1538 31.7886 22.9804 32.6152 24 32.6152Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-alert-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
